import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { APIResponse } from "src/utils/interface";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: 'root'
})
export class RemarksService {

  constructor(
    private http: HttpClient,
    private _notify: NotificationService,
    private _progress: ProgressLoaderService
  ) { }

  async saveRemark(loan_id, data) {
    try {
      console.log("data", data);
      this._progress.show('Saving Remark...')
      let res = (await this.http.post(environment.API + `/remarks/saveRemark/${loan_id}`, data)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Remark Saved!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("ERROR", err)
    }
  }

}
