import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Credential, APIResponse } from "src/utils/interfaces";
import { environment } from "src/environments/environment";
import { ReplaySubject } from "rxjs";
import * as firebase from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  userAccount$: ReplaySubject<any> = new ReplaySubject<any>(1);
  firebaseUser: ReplaySubject<firebase.User> = new ReplaySubject<firebase.User>(
    1
  );
  constructor(
    private router: Router,
    private http: HttpClient,
    private _fireAuth: AngularFireAuth,
    private _progress: ProgressLoaderService,
    private _notify: NotificationService
  ) {
    _fireAuth.auth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .catch((err) => console.error(err));
    _fireAuth.authState.subscribe((user) => {
      this.firebaseUser.next(user);
      if (user) {
        localStorage.setItem("firebaseUser", JSON.stringify(user));
        localStorage.setItem("welcome", "true");
      } else {
        localStorage.removeItem("firebaseUser");
      }
    });
  }

  get userData() {
    return JSON.parse(localStorage.getItem("user"))
  }


  get isLoggedIn(): boolean {
    return !!localStorage.getItem("token");
  }

  async signOut() {
    await this.removedLocalStorage();
    this.router.navigate(["/login"]);
  }

  async removedLocalStorage() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("userType");
    return true;
  }

  async login(data) {
      try {
        const login = await this._fireAuth.auth.signInWithEmailAndPassword(
          data.email,
          data.password
        );
        if (login) {
          let user_uid = login.user.uid;
          console.log("user_uid", user_uid);
          let res = (await this.http
            .get(environment.API + `/user/findUserUid/${user_uid}`)
            .toPromise()) as APIResponse;
          if (res.status === "success") {
            console.log("USER", res.result);
            this.userAccount$.next(res.result);
            localStorage.setItem("user", JSON.stringify(res.result))
            this.navigate(res.result)
            return res            
          }
        }
      } catch (error) {
        console.log("ERROR:", error);
        this._notify.message("Error Loging In", "Error")
        return error.message;
      }
  }

  navigate(user) {
    console.log("user", user);
    if(user.userType == 0){
      this.router.navigate(["/admin/new-applications"])
    }else{
      this.router.navigate(["/branch/new-applications"])
    }
  }

  async firebaseSignup(data) {
    try {
      this._progress.show("Creating Admin Account...");
      let userdata;
      let res = await this._fireAuth.auth.createUserWithEmailAndPassword(
        data.email,
        data.password
      );
      if (res) {
        let uid = res.user.uid;
        data.uid = uid
        let user = await this.createUserAccount(data)
        return user
      }
    } catch (error) {
      switch (error.code) {
        case "auth/email-already-in-use":
          console.log("Email already in use", "ERROR");
          break;
        case "auth/weak-password":
          console.log("Password must be greater than 6 characters");
          break;
        default:
          console.log("Failed to create account", "ERROR");
      }
    }
  }

  async createUserAccount(data){
    try{
      let res = (await this.http.post(environment.API + `/user/createAccount`, { data: data })
      .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("User Created!", "Success")
        return res.result;
      }
    throw new Error(res.code);
    }catch(err){
      this._notify.message("Error Found!", "failed");
      console.log("Error", err);
    }
  }


  async resetPassword(email: string) {
    try {
      this._progress.show("Loading...");
      await this._fireAuth.auth.sendPasswordResetEmail(email);
      this._progress.hide();
      this._notify.message("Link to reset password was sent on your email.", "Success")
      return true;
    } catch (error) {
      this._progress.hide();
      if (error.code === "auth/user-not-found") {
        return this._notify.message("User not found", "ERROR");
      }
      this._notify.message("Failed to reset password", "ERROR");
    }
  }


  async sendOTPCode(otpCode, email) {
    try {
      this._progress.show('Sending OTP code...')
      let res = (await this.http.post(environment.API + `/sendOTPCode`, { otpCode: otpCode, email: email })
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("Code Sent!", "Success")
        return res.result;
      }
      throw new Error(res.code);
    } catch (err) {
      console.log("code not sent, something went wrong please contact the developers");
      console.log(err)
    }
  }

  async allowUserToEdit(user_id){
    try{
      this._progress.show('Allowing user to edit...')
      let res = (await this.http.post(environment.API + `/user/allowUserToEdit/${user_id}`, {user_id: user_id})
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        this._notify.message("User has been allowed to edit", "success");
        return res.result;
      }
      throw new Error(res.code);
    }catch(err){
      console.log("Error: ", err);
    }
  }



}
