export const environment = {
  production: false,
  // API: "http://localhost:5000/api", //local
  API: "http://18.141.203.204//api", //live
  firebaseConfig: {
    apiKey: "AIzaSyCheoUSEacECqvsiot_AXWkR0PgC3Bs3a0",
    authDomain: "new-motorlandia.firebaseapp.com",
    projectId: "new-motorlandia",
    storageBucket: "new-motorlandia.appspot.com",
    messagingSenderId: "220508888184",
    appId: "1:220508888184:web:4e123a2497c204e664218d",
    measurementId: "G-NNW1QJTZKV"
  }
};

