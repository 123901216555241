import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AsyncPipe } from "../../node_modules/@angular/common";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from '@angular/common';

import { MatIconModule } from "@angular/material/icon"
import { MatProgressBarModule } from "@angular/material/progress-bar"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { MatCardModule } from "@angular/material/card"
import { MatTableModule } from "@angular/material/table"
import { MatPaginatorModule } from "@angular/material/paginator"
import { MatSidenavModule } from "@angular/material/sidenav"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatTooltipModule } from "@angular/material/tooltip"
import { MatListModule } from "@angular/material/list"
import { MatChipsModule } from "@angular/material/chips"
import { MatTabsModule } from "@angular/material/tabs"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatInputModule } from "@angular/material/input"
import { MatSortModule } from "@angular/material/sort";
import { MatAutocompleteModule } from "@angular/material/autocomplete"
import { MatSelectModule } from "@angular/material/select";
import { MatButtonToggleModule } from "@angular/material/button-toggle"
import { MatDialogModule } from '@angular/material/dialog';

import { ChartsModule, ThemeService } from 'ng2-charts';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent, AccessDeniedDialog } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TodoComponent } from './apps/todo-list/todo/todo.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { ContentAnimateDirective } from './shared/directives/content-animate.directive';
import { TodoListComponent } from './apps/todo-list/todo-list.component';
import { HttpClientModule } from "@angular/common/http";
import { SharedModule } from "src/app/shared/shared.module";

import { ConfirmationDialogComponent } from "./shared/dialogs/dialogs.component";
import { ProgressLoaderComponent } from './shared/progress-loader/progress-loader.component';
import { LoginComponent, ForgotPasswordDialog } from './login/login.component';

import { environment } from "src/environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirePerformanceModule } from "@angular/fire/performance";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { QRCodeModule } from 'angularx-qrcode';
import {QrcodeDialogComponent, ImageViewerDialog} from "./shared/dialogs/dialogs.component";
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LoanDetailsComponent } from './loan-details/loan-details.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    TodoListComponent,
    TodoComponent,
    SpinnerComponent,
    ContentAnimateDirective,
    ConfirmationDialogComponent,
    ProgressLoaderComponent,
    LoginComponent,
    ForgotPasswordDialog,
    AccessDeniedDialog,
    QrcodeDialogComponent,
    ImageViewerDialog,
    LoanDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    HttpClientModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTooltipModule,
    MatListModule,
    MatChipsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatButtonToggleModule,
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirePerformanceModule,
    MatDatepickerModule,
    MatNativeDateModule,
    QRCodeModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    })
  ],
  providers: [
    AsyncPipe,
    ThemeService,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  entryComponents: [
    ConfirmationDialogComponent, 
    ForgotPasswordDialog, 
    AccessDeniedDialog, 
    QrcodeDialogComponent, 
    ImageViewerDialog
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
