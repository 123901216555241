import { MatSnackBar } from "@angular/material/snack-bar";
import { Injectable, Component, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

/**
 * Service for displaying notifications system wide
 *
 * @export
 * @class NotificationService
 */
@Injectable( {
  providedIn: "root"
} )
export class NotificationService {
  /**
   * Creates an instance of NotificationService.
   * @param {MatSnackBar} snackbar
   * @param {MatDialog} dialog
   * @param {LinkService} _link
   * @param {Router} router
   */
  constructor (
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router
  ) { }

  /**
   * Displays a snackbar notification at the bottom of the screen
   *
   * @param {string} message message to display
   * @param {string} style style of the message (success, warning, danger, info)
   * @param {number} [duration=2500] duration of the message
   * @param {string} [action=null] action button to display
   */
  message (
    message: string,
    style: string,
    duration: number = 2500,
    action: string = null
  ) {
    if ( style == "ERROR" || style == "WARNING" ) {
      duration = 4000;
    }
    this.snackbar.open( message, action, {
      panelClass: ["snackbar", `snackbar--${ style.toLowerCase() }`],
      duration
    } );
  }

  /**
   * Displays an alert that must be dismissed to continue workflow
   *
   * @param {string} title title of alert
   * @param {string} message alert message
   * @param {string} noText text for the decline button
   * @param {string} yesText test for the accept button
   * @param {boolean} destructive is this alert for a destructive command (delete, deactivate)
   * @returns
   */
  openAlert (
    title: string,
    message: string,
    noText: string,
    yesText: string,
    destructive: boolean
  ) {
    return new Promise( ( resolve, reject ) => {
      const dialogRef = this.dialog.open( DialogConfirmationAlert, {
        data: { title, message, noText, yesText, destructive },
        panelClass: 'delete-modal',
      } );
      dialogRef.afterClosed().subscribe( decision => resolve( decision ) );
    } );
  }
}

/**
 * Alert modal for openAlert function
 *
 * @export
 * @class DialogConfirmationAlert
 */
@Component( {
  selector: "dialog-confirmation-alert",
  templateUrl: "../shared/dialogs/templates/confirmation-alert.html"
} )
export class DialogConfirmationAlert {
  /**
   * Creates an instance of DialogConfirmationAlert.
   * @param {MatDialogRef<DialogConfirmationAlert>} dialogRef
   * @param {{
   *       title: string,
   *       message: string,
   *       noText: string,w
   *       yesText: string,
   *       destructive: boolean
   *     }} data
   */
  constructor (
    private dialogRef: MatDialogRef<DialogConfirmationAlert>,
    @Inject( MAT_DIALOG_DATA )
    public data: {
      title: string;
      message: string;
      noText: string;
      yesText: string;
      destructive: boolean;
    }
  ) { }

  /**
   * Returns user alert decision (accepted, declined)
   *
   * @param {boolean} decision accepted or declined status
   */
  submitResult ( decision: boolean ) {
    this.dialogRef.close( decision );
  }
}
