import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Credential, APIResponse } from "src/utils/interfaces";
import { environment } from "src/environments/environment";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";


@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(
    private router: Router,
    private http: HttpClient,
    private _progress: ProgressLoaderService,
    private _notify: NotificationService
  ) { }

  async getUserContacts(user_id){
    try{
      let res = (await this.http.get(environment.API + `/contacts/getUserContacts/${user_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        return res.result;
      }
      throw new Error(res.code);
    }catch(err){
      console.log("Error: ", err);
    }
  }
}
