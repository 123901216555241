import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { LoanService } from '../services/loan.service';
import { ContactsService } from '../services/contacts.service';
import { UserService } from "../services/user.service"
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  ConfirmationDialogComponent,
  ImageViewerDialog,
} from "src/app/shared/dialogs/dialogs.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { RemarksService } from '../services/remarks.service';

@Component({
  selector: 'app-loan-details',
  templateUrl: './loan-details.component.html',
  styleUrls: ['./loan-details.component.scss']
})
export class LoanDetailsComponent implements OnInit {
  province = require("../../json/province.json");
  city = require("../../json/city.json");
  brgy = require("../../json/brgy.json");
  loan_id:any = null
  loan: any = null
  user_id: any
  contacts: any[] = null
  userAccount: any

  remarksColumns: string[] = ["Remarks", "Author", "DateCreated", "action"];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  remarks: MatTableDataSource<any>;
  remarksSrc: any[] = [];
  remarkForm: FormGroup = null;
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _loan: LoanService,
    private _contacts: ContactsService,
    private _user: UserService,
    private dialog: MatDialog,
    private _remark: RemarksService,
  ) {
    this.userAccount = this._user.userData
    
    this.remarkForm = new FormGroup({
      remarkText: new FormControl(null),
      author: new FormControl(null),
    });

  }

  
  

  ngOnInit() {
    this.loan_id = this._route.snapshot.params["loan_id"];
    this.getLoanByID(this.loan_id)
  }

  async getLoanByID(loan_id){
    let res = await this._loan.getLoanByID(loan_id)

    this.user_id = res.user._id
    this.remarksSrc = res.remarks;
    this.remarksDataSource();
    this.province.map((list: any) => {
      if (list.provCode == res.user.address.province) {
        res.user.address.provinceName = list.provDesc;
      }
    });

    let records = this.city.RECORDS;
    records.map((list: any) => {
      if (list.citymunCode == res.user.address.city) {
        res.user.address.cityName = list.citymunDesc;
      }
    });

    this.loan = res
    console.log("this.loan", this.loan);
    this.getUserContacts();
  }

  async getUserContacts(){
    let res = await this._contacts.getUserContacts(this.user_id)
    this.contacts = res
  }


  async updateToOnProcess(user_id, loan_id){
    let res = await this._loan.updateToOnProcess(user_id, loan_id)
    this.getLoanByID(loan_id)
  }

  async updateToArchived(user_id, loan_id){
    let res = await this._loan.updateToArchived(user_id, loan_id)
    this.getLoanByID(loan_id)
  }

  async allowUserToEdit(user_id){
    let res = await this._user.allowUserToEdit(user_id)
    console.log("res", res);
  }

  async updateTrackerStatus(user_id, loan_id, tracker_status){
    await this._loan.updateLoanTracker(user_id, loan_id, {loanTrackerStatus: tracker_status});
    this.getLoanByID(loan_id)
  }

  showImageOnModal(image) {
    const dialogRef = this.dialog.open(ImageViewerDialog, {
      panelClass: "",
      data: image,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log("result");
      }
    });
  }

  remarksDataSource() {
    this.remarks = new MatTableDataSource(this.remarksSrc);
    this.remarks.sort = this.sort;
    this.remarks.paginator = this.paginator;
  }

  remarkTextError: boolean = false;
  remarkAuthorError: boolean = false;
  async submitRemark() {

    let value = this.remarkForm.value;
    if (value.remarkText == null || value.remarkText == "") {
      this.remarkTextError = true;
    }
    if (value.author == null || value.author == "") {
      this.remarkAuthorError = true;
    }

    if (
      (value.remarkText !== null || value.remarkText !== "") &&
      (value.author !== null || value.author !== "")
    ) {
      let res = await this._remark.saveRemark(
        this.loan_id,
        this.remarkForm.value
      );
      this.remarkForm.patchValue({
        remarkText: null,
        author: null,
      });
      this.remarksSrc.splice(0, 0, res);
      console.log("this.remarksSrc", this.remarksSrc);
      this.remarksDataSource();
    }
  }

  remarksTextChange() {
    this.remarkTextError = false;
  }

  authorTextChange() {
    this.remarkAuthorError = false;
  }

  viewingRemark: boolean = false;
  viewRemark(remark) {
    this.viewingRemark = true;
    console.log("remark", remark);
    this.remarkForm.patchValue({
      remarkText: remark.remarkText,
      author: remark.author,
    });
  }

  doneViewing() {
    this.viewingRemark = false;
    this.remarkForm.patchValue({
      remarkText: null,
      author: null,
    });
  }

  viewLoan(loan_id){
    this._router.navigate([`/loan-details/${loan_id}`])
  }

}
