import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Credential, APIResponse } from "src/utils/interfaces";
import { environment } from "src/environments/environment";
import { ProgressLoaderService } from "src/app/shared/progress-loader/progress-loader.service";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: 'root'
})
export class LoanService {

  constructor(
    private router: Router,
    private http: HttpClient,
    private _progress: ProgressLoaderService,
    private _notify: NotificationService
  ) { }

  async getLoans(loan_type){
    try{
      this._progress.show('Fetching Loan Details...')
      let res = (await this.http.get(environment.API + `/loan/getLoans/${loan_type}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    }catch(err){
      console.log("Error: ", err);
    }
  }

  async getLoansBranch(loan_type, branch_id){
    try{
      this._progress.show('Fetching Loan Details...')
      let res = (await this.http.get(environment.API + `/loan/getLoansBranch/${loan_type}/${branch_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    }catch(err){
      console.log("Error: ", err);
    }
  }



  async getLoanByID(loan_id){
    try{
      this._progress.show('Fetching Loan Details...')
      let res = (await this.http.get(environment.API + `/loan/getLoanByID/${loan_id}`)
        .toPromise()) as APIResponse;
      if (res.status === "success") {
        this._progress.hide();
        return res.result;
      }
      throw new Error(res.code);
    }catch(err){
      console.log("Error: ", err);
    }
  }

  async updateToOnProcess(user_id, loan_id){
    try{
      this._progress.show("Updating Loan Status...");
      let res = (await this.http.post(environment.API + `/loan/updateToOnProcess/${loan_id}`, {user_id: user_id})
          .toPromise()) as APIResponse;
        if (res.status === "success") {
          this._progress.hide();
          return res.result;
        }
        throw new Error(res.code);
    }catch(err){
      console.log("Error: ", err);
    }
  }

  async updateToArchived(user_id, loan_id){
    try{
      this._progress.show("Updating Loan Status...");
      let res = (await this.http.post(environment.API + `/loan/updateToArchived/${loan_id}`, {user_id: user_id})
          .toPromise()) as APIResponse;
        if (res.status === "success") {
          this._progress.hide();
          return res.result;
        }
        throw new Error(res.code);
    }catch(err){
      console.log("Error: ", err);
    }
  }

  async updateLoan(loan_id, data){
    try{
      this._progress.show("Updating Loan...");
      let res = (await this.http.post(environment.API + `/loan/updateLoan/${loan_id}`, {data: data})
          .toPromise()) as APIResponse;
        if (res.status === "success") {
          this._progress.hide();
          this._notify.message("Loan Updated!", "success")
          return res.result;
        }
        throw new Error(res.code);
    }catch(err){
      console.log("Error: ", err);
    }
  }

  async updateLoanTracker(user_id, loan_id, data){
    try{
      this._progress.show("Updating Loan...");
      let res = (await this.http.post(environment.API + `/loan/updateLoanTracker/${loan_id}/${user_id}`, {data: data})
          .toPromise()) as APIResponse;
        if (res.status === "success") {
          this._progress.hide();
          this._notify.message("Loan Updated!", "success")
          return res.result;
        }
        throw new Error(res.code);
    }catch(err){
      console.log("Error: ", err);
    }
  }


}
