import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { UserService } from "src/app/services/user.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";

/**
 * Password form control
 * @const {FormControl}
 */
const password = new FormControl("", Validators.required);
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  authForm: FormGroup
  constructor(
    private _user: UserService
  ) { }

  ngOnInit() {
    this.authForm = new FormGroup({
      email: new FormControl(null),
      password: new FormControl(null),
    })
  }

  async login(){
    let values = this.authForm.value
    let data = {
      email: values.email,
      password: values.password,
    }
    await this._user.login(data)
  }

  forgotPassword(){

  }

}


@Component({
  selector: "dialog-forgot-password",
  templateUrl: "./forgot-password-dialog.html",
  styleUrls: ["./login.component.scss"],
})
export class ForgotPasswordDialog {
  forgotPasswordForm: FormGroup;
  isEmailInvalid: boolean = true
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ForgotPasswordDialog>,
  ) {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl(null)
    })
  }

  onChangeEmail(e) {
    let val = e.target.value
    if (val.includes(".com")) {
      this.isEmailInvalid = false
    }
  }

  cancel() {
    this._dialogRef.close()
  }

  confirm() {
    this._dialogRef.close(this.forgotPasswordForm.value.email)
  }


}
