import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public uiBasicCollapsed = false;
  public samplePagesCollapsed = true;
  userAccount: any = null
  currentDate: string;
  constructor(
    private _user: UserService,
    private _router: Router,
    private dialog: MatDialog,
  ) {
    this.userAccount = this._user.userData
  }


  ngOnInit() {
    const body = document.querySelector('body');
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }




  accessDenied() {
    const dialogRef = this.dialog.open(AccessDeniedDialog, {
      panelClass: "right-modal",
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
      }
    });
  }



}

@Component({
  selector: "dialog-access-denied",
  templateUrl: "./access-denied-dialog.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class AccessDeniedDialog {
  constructor(
    private _dialogRef: MatDialogRef<AccessDeniedDialog>,
  ) {
    setTimeout(() => {
      this._dialogRef.close()
    }, 15000);
  }

}

