import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Progress loader service for dynamically displaying indeterminate progress loader app wide
 * @export
 * @class ProgressLoaderService
 */
@Injectable({
  providedIn: 'root'
})
export class ProgressLoaderService {

  /**
   * progress loader state subject
   * @type {BehaviorSubject<any>}
   */
  public loadingSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false)


  /**
   *Creates an instance of ProgressLoaderService.
   */
  constructor() { }

  /**
   * Enables progress loader with message
   *
   * @param {string} message message to display
   */
  show(message: string) {
    this.loadingSubject.next(message)
  }

  /**
   * Disables the progress loader
   *
   */
  hide() {
    this.loadingSubject.next(null)
  }

  /**
   * Returns current status of the progress loader
   *
   * @returns {Observable<any>}
   */
  getStatus(): Observable<any> {
    return this.loadingSubject.asObservable()
  }
}
